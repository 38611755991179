import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/estilo.css";
import Header from './Common/Header';
import NavTop from './Common/NavTop';
import NavMain from './Common/NavMain';
import Footer from './Common/Footer';
import { TextInput } from './Common/TextInput';
import FooterMenu from './Common/FooterMenu';
import InstrumentoDataService from "../services/instrumento.service";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Toogles from './Common/Toogles';


class EditarInstrumento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // glossario: [],
            // nome: "",
            NomeBase: "",
            NomeDicionario: "",
            NomeOriginal: "",
            ClassificacaoGeral: "",
            ContextoMusical: "",
            Curiosidades: "",
            DadosGeograficos: "",
            Idioma: "",
            Linkaudio: "",
            Linkfoto1: "",
            Linkfoto2: "",
            Linkilustracao: "",
            Linkvideo: "",
            PrincipaisMateriais: "",
            Sinonimos: "",
            TecnicaConvencional: "",
            TecnicaEstendida: "",
            Variacoes: "",
            // audio: "",
            audioexecucao: "",
            audiofonte: "",
            // categoria: "",
            classificacao: "",
            createdAt: "",
            fotografia1: "",
            fotografia1fonte: "",
            fotografia2: "",
            fotografia2fonte: "",
            id: "",
            // idCategoria: "",
            // imagem: "",
            // texto: "",
            updatedAt: "",
            // video: "",
            videoexecucao: "",
            videofonte: "",
            sample: 0,
            published: 0,
            instrum: {},
        };
        this.handleDataChange = this.handleDataChange.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        this.handleDataClassificacaoGeralChange = this.handleDataClassificacaoGeralChange.bind(this);
        this.handleDataPrincipaisMateriaisChange = this.handleDataPrincipaisMateriaisChange.bind(this);
        this.handleDataVariacoesChange = this.handleDataVariacoesChange.bind(this);
        this.handleDataTecnicaConvencionalChange = this.handleDataTecnicaConvencionalChange.bind(this);
        this.handleDataTecnicaEstendidaChange = this.handleDataTecnicaEstendidaChange.bind(this);
        this.handleDataDadosGeograficosChange = this.handleDataDadosGeograficosChange.bind(this);
        this.handleDataContextoMusicalChange = this.handleDataContextoMusicalChange.bind(this);
        this.handleDataCuriosidadesChange = this.handleDataCuriosidadesChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onClickPublished = this.onClickPublished.bind(this);
        this.onClickSample = this.onClickSample.bind(this);
        this.closeHandle = this.closeHandle.bind(this);
        this.entendiHandle = this.entendiHandle.bind(this);
    }
    onChangeText = (event) => {

        // //console.log(event);
    }
    onClickPublished = (event) => {
        if (this.state.published === 1) {
            this.setState({
                published: 0
            });
        } else {
            this.setState({
                published: 1
            });
        }
    }


    onClickSample = (event) => {
        if (this.state.sample === 1) {
            this.setState({
                sample: 0
            });
        } else {
            this.setState({
                sample: 1
            });
        }
    }
    entendiHandle = e => {
        e.preventDefault();
        window.location = './home';
        console.log('close button clicked')
    }

    closeHandle = e => {
        e.preventDefault();
        console.log('close button clicked')
    }
    async handleSubmit(event) {
        event.preventDefault();

        let data = JSON.stringify(this.state);
        console.log();
        if (this.state.id !== '') {


            InstrumentoDataService.update(this.state.id, data)
                .then(response => {
                    alert(response.data.message);
                    window.location = './instrumentos';
                    // showMessage('', response.data.message, true, this.entendiHandle, 'Entendi', this.closeHandle)

                })
                .catch(e => {
                    console.log(e);
                    // this.setState({ itemserror: this.state.itemserror.concat(data) })
                    // this.setState({ counterror: this.state.counterror + 1 });
                });
        }
        else {
            InstrumentoDataService.create( data)
                .then(response => {
                    alert(response.data.message);
                    window.location = './instrumentos';
                    // showMessage('', response.data.message, true, this.entendiHandle, 'Entendi', this.closeHandle)

                })
                .catch(e => {
                    // debugger
                    console.log(e);
                    alert(e.response.data.message);
                    // this.setState({ itemserror: this.state.itemserror.concat(data) })
                    // this.setState({ counterror: this.state.counterror + 1 });
                });
        }
    };

    handleLogout = () => {

    }
    handleDataClassificacaoGeralChange(event, editor) {
        this.setState({ ClassificacaoGeral: editor.getData() });
    }
    handleDataPrincipaisMateriaisChange(event, editor) {
        this.setState({ PrincipaisMateriais: editor.getData() });
    }
    handleDataVariacoesChange(event, editor) {
        this.setState({ Variacoes: editor.getData() });
    }
    handleDataTecnicaConvencionalChange(event, editor) {
        this.setState({ TecnicaConvencional: editor.getData() });
    }
    handleDataTecnicaEstendidaChange(event, editor) {
        this.setState({ TecnicaEstendida: editor.getData() });
    }
    handleDataDadosGeograficosChange(event, editor) {
        this.setState({ DadosGeograficos: editor.getData() });
    }
    handleDataContextoMusicalChange(event, editor) {
        this.setState({ ContextoMusical: editor.getData() });
    }
    handleDataCuriosidadesChange(event, editor) {
        this.setState({ Curiosidades: editor.getData() });
    }
    handleDataChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    componentDidMount() {
        this.getInstrumento();
        // //console.log(this.state.instrum);
    }

    carregarInstrumentosIdiomas() {
        // //console.log('carregarInstrumentosIdiomas')
        //console.log(this.state.instrum);
    }

    getInstrumento() {
        let instrumento = (this.props.match.params.instrumento !== undefined ? this.props.match.params.instrumento : '-');
        this.setState({ letra: instrumento.toUpperCase() })
        InstrumentoDataService.get(instrumento)
            .then(response => {
                //console.log('carregarInstrumentos')
                //console.log(response.data)
                //console.log(response.data.length)

                if (response.data) {
                    this.setState({
                        instrum: response.data,
                        // nome: response.data.nome,
                        // NomeBase: response.data.NomeBase,
                        id: response.data.id,
                        NomeDicionario: response.data.NomeDicionario,
                        NomeOriginal: response.data.NomeOriginal,
                        ClassificacaoGeral: response.data.ClassificacaoGeral,
                        ContextoMusical: response.data.ContextoMusical,
                        Curiosidades: response.data.Curiosidades,
                        DadosGeograficos: response.data.DadosGeograficos,
                        Idioma: response.data.Idioma,
                        Linkaudio: response.data.Linkaudio,
                        Linkfoto1: response.data.Linkfoto1,
                        Linkfoto2: response.data.Linkfoto2,
                        Linkilustracao: response.data.Linkilustracao,
                        Linkvideo: response.data.Linkvideo,
                        PrincipaisMateriais: response.data.PrincipaisMateriais,
                        Sinonimos: response.data.Sinonimos,
                        TecnicaConvencional: response.data.TecnicaConvencional,
                        TecnicaEstendida: response.data.TecnicaEstendida,
                        Variacoes: response.data.Variacoes,
                        audio: response.data.audio,
                        audioexecucao: response.data.audioexecucao,
                        audiofonte: response.data.audiofonte,
                        // categoria: response.data.categoria,
                        classificacao: response.data.classificacao,
                        createdAt: response.data.createdAt,
                        fotografia1: response.data.fotografia1,
                        fotografia1fonte: response.data.fotografia1fonte,
                        fotografia2: response.data.fotografia2,
                        fotografia2fonte: response.data.fotografia2fonte,
                        // id: response.data.id,
                        // idCategoria: response.data.idCategoria,
                        // imagem: response.data.imagem,
                        texto: response.data.texto,
                        updatedAt: response.data.updatedAt,
                        video: response.data.video,
                        videoexecucao: response.data.videoexecucao,
                        videofonte: response.data.videofonte,
                        sample: response.data.sample,
                        published: response.data.published,
                    });
                    this.carregarInstrumentosIdiomas();
                }
                else {
                    this.setState({ Error: true });
                }
            })
            .catch(e => {
                //console.log(e);
            });
    }

    render() {

        return (
            <div className="page-wrapper">
                <NavTop></NavTop>
                <NavMain></NavMain>
                <Header title="Editando instrumento" ></Header>
                <Container className='pb-5'>
                    <form onSubmit={this.handleSubmit}>
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput type="hidden" labelValue={"id"} id={"id"} name={"id"} defaultValue={this.state.id} value={this.state.id} className="form-control" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput onChange={this.handleDataChange} labelValue={"Nome"} id={"NomeDicionario"} name={"NomeDicionario"} value={this.state.NomeDicionario} required={'required'} className="form-control" />
                            </Col>
                        </Row>


                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput onChange={this.handleDataChange} labelValue={"Nome Original"} id={"NomeOriginal"} name={"NomeOriginal"} value={this.state.NomeOriginal} required={'required'} className="form-control" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput onChange={this.handleDataChange} labelValue={"Idioma"} id={"Idioma"} name={"Idioma"} defaultValue={this.state.Idioma} value={this.state.Idioma} required={'required'} className="form-control" />
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput onChange={this.handleDataChange} labelValue={"Outros nomes"} id={"Sinonimos"} name={"Sinonimos"} defaultValue={this.state.Sinonimos} value={this.state.Sinonimos} className="form-control" />
                            </Col>
                        </Row>


                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput labelValue={"Classificação Geral"} id={"ClassificacaoGeral"} onChange={this.handleDataClassificacaoGeralChange} type="ckeditor" name={"ClassificacaoGeral"} defaultValue={this.state.ClassificacaoGeral} value={this.state.ClassificacaoGeral} className="form-control" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput labelValue={"Principais Materiais"} id={"PrincipaisMateriais"} onChange={this.handleDataPrincipaisMateriaisChange} type="ckeditor" name={"PrincipaisMateriais"} defaultValue={this.state.PrincipaisMateriais} value={this.state.PrincipaisMateriais} className="form-control" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput labelValue={"Variações"} id={"Variacoes"} onChange={this.handleDataVariacoesChange} type="ckeditor" name={"Variacoes"} defaultValue={this.state.Variacoes} value={this.state.Variacoes} className="form-control" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput labelValue={"Técnica Convencional"} id={"TecnicaConvencional"} onChange={this.handleDataTecnicaConvencionalChange} type="ckeditor" name={"TecnicaConvencional"} defaultValue={this.state.TecnicaConvencional} value={this.state.TecnicaConvencional} className="form-control" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput labelValue={"Técnica Estendida"} id={"TecnicaEstendida"} onChange={this.handleDataTecnicaEstendidaChange} type="ckeditor" name={"TecnicaEstendida"} defaultValue={this.state.TecnicaEstendida} value={this.state.TecnicaEstendida} className="form-control" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput labelValue={"Dados Geograficos"} id={"DadosGeograficos"} onChange={this.handleDataDadosGeograficosChange} type="ckeditor" name={"DadosGeograficos"} defaultValue={this.state.DadosGeograficos} value={this.state.DadosGeograficos} className="form-control" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput labelValue={"Contexto Musical"} id={"ContextoMusical"} name={"ContextoMusical"} onChange={this.handleDataContextoMusicalChange} type="ckeditor" defaultValue={this.state.ContextoMusical} value={this.state.ContextoMusical} className="form-control" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput labelValue={"Curiosidades"} id={"Curiosidades"} name={"Curiosidades"} onChange={this.handleDataCuriosidadesChange} type="ckeditor" defaultValue={this.state.Curiosidades} value={this.state.Curiosidades} className="form-control" />
                            </Col>
                        </Row>





                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item active" aria-current="page">Ilustração</li>
                            </ol>
                        </nav>
                        {/* ilustracao */}
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput onChange={this.handleDataChange} labelValue={"Link"} id={"Linkilustracao"} name={"Linkilustracao"} defaultValue={this.state.Linkilustracao} value={this.state.Linkilustracao} className="form-control" />
                            </Col>
                        </Row>
                        {/* audio */}
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item active" aria-current="page">Áudio</li>
                            </ol>
                        </nav>
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput onChange={this.handleDataChange} labelValue={"Link"} id={"Linkaudio"} name={"Linkaudio"} defaultValue={this.state.Linkaudio} value={this.state.Linkaudio} className="form-control" />
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput onChange={this.handleDataChange} labelValue={"Execução"} id={"audioexecucao"} name={"audioexecucao"} defaultValue={this.state.audioexecucao} value={this.state.audioexecucao} className="form-control" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput onChange={this.handleDataChange} labelValue={"Fonte"} id={"audiofonte"} name={"audiofonte"} defaultValue={this.state.audiofonte} value={this.state.audiofonte} className="form-control" />
                            </Col>
                        </Row>



                        {/* foto2 */}
                        {/* <Row>
                            <Col sm={12} className="form-group">
                                <TextInput onChange={this.handleDataChange} labelValue={"Linkfoto2"} id={"Linkfoto2"} name={"Linkfoto2"} defaultValue={this.state.Linkfoto2} value={this.state.Linkfoto2} className="form-control" />
                            </Col>
                        </Row> */}
                        {/* video */}
                        {/* <div class="form-group row"> */}

                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item active" aria-current="page">Video</li>
                            </ol>
                        </nav>
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput onChange={this.handleDataChange} labelValue={"Link"} id={"Linkvideo"} name={"Linkvideo"} defaultValue={this.state.Linkvideo} value={this.state.Linkvideo} className="form-control" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput onChange={this.handleDataChange} labelValue={"Execução"} id={"videoexecucao"} name={"videoexecucao"} defaultValue={this.state.videoexecucao} value={this.state.videoexecucao} className="form-control" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput onChange={this.handleDataChange} labelValue={"Fonte"} id={"videofonte"} name={"videofonte"} defaultValue={this.state.videofonte} value={this.state.videofonte} className="form-control" />
                            </Col>
                        </Row>
                        {/* </div> */}
                        {/* fotografia 1*/}
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item active" aria-current="page">Fotografia</li>
                            </ol>
                        </nav>
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput onChange={this.handleDataChange} labelValue={"Link"} id={"Link"} name={"Linkfoto1"} defaultValue={this.state.Linkfoto1} value={this.state.Linkfoto1} className="form-control" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput onChange={this.handleDataChange} labelValue={"Fotografia"} id={"fotografia1"} name={"fotografia1"} defaultValue={this.state.fotografia1} value={this.state.fotografia1} className="form-control" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput onChange={this.handleDataChange} labelValue={"Fonte"} id={"fotografia1fonte"} name={"fotografia1fonte"} defaultValue={this.state.fotografia1fonte} value={this.state.fotografia1fonte} className="form-control" />
                            </Col>
                        </Row>
                        {/* fotografia 2*/}
                        {/* <Row>
                            <Col sm={12} className="form-group">
                                <TextInput onChange={this.handleDataChange} labelValue={"fotografia2"} id={"fotografia2"} name={"fotografia2"} defaultValue={this.state.fotografia2} value={this.state.fotografia2} className="form-control" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput onChange={this.handleDataChange} labelValue={"fotografia2fonte"} id={"fotografia2fonte"} name={"fotografia2fonte"} defaultValue={this.state.fotografia2fonte} value={this.state.fotografia2fonte} className="form-control" />
                            </Col>
                        </Row> */}


                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput readonly={true} labelValue={"createdAt"} id={"createdAt"} name={"createdAt"} defaultValue={this.state.createdAt} value={this.state.createdAt} className="form-control" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput readonly={true} labelValue={"updatedAt"} id={"updatedAt"} name={"updatedAt"} defaultValue={this.state.updatedAt} value={this.state.updatedAt} className="form-control" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} className="form-group">
                                <Toogles
                                    id="sample"
                                    name="sample"
                                    type="sample"
                                    placeholder=""
                                    labelValue=" sample"
                                    isChecked={parseInt(this.state.sample) === 1}
                                    FunctionClickCall={this.onClickSample}
                                    FunctionChangeCall={this.onChangeText}
                                />

                            </Col>
                            <Col sm={6} className="form-group">
                                <Toogles
                                    id="published"
                                    name="published"
                                    type="published"
                                    placeholder=""
                                    labelValue="published"
                                    isChecked={parseInt(this.state.published) === 1}
                                    FunctionClickCall={this.onClickPublished}
                                    FunctionChangeCall={this.onChangeText}
                                />

                            </Col>
                        </Row>


                        <Row className="row">
                            <Col className="form-group col-md-4">
                                <div className="">
                                    <button className="btn btn-primary mr-2" type="submit">Salvar instrumento</button>
                                    <Link to="/instrumentos" className="btn btn-warning" type="button">Voltar</Link>
                                </div>
                            </Col>
                            <Col className="form-group col-md-4">
                                <div className="">

                                </div>
                            </Col>
                        </Row>


                    </form>
                </Container>
                <FooterMenu></FooterMenu>
                <Footer></Footer>
            </div>
        );
    }

}
export default EditarInstrumento;