import React, { Component } from 'react';
import CacheBuster from 'react-cache-buster';
import { BrowserRouter, Switch } from 'react-router-dom';
import PublicRoute from './components/Common/PublicRoute';
import { ToastContainer } from 'react-toastify';
import Home from './components/Home';
import Plano from './components/Planos';
import Instrumento from './components/Instrumentos';
import Login from './components/Login';
import GenericNotFound from './components/Common/GenericNotFound';
import ImportInstrumento from './components/ImportInstrumento';
import ImportSinonimo from './components/ImportSinonimo';
import ImportGlossario from './components/ImportGlossario';
import sinonimos from './components/sinonimos';
import politica from './components/politica-privacidade';
import TermosCondicoes from './components/termos-condicoes';
import glossario from './components/glossario';
import PrivateRoute from './components/Common/PrivateRoute';
import EditarInstrumento from './components/EditarInstrumento';

import EditarTermos from './components/EditarTermos';
import EditarGlossario from './components/EditarGlossario';
import EditarSinonimos from './components/EditarSinonimos';
import EditarPolitica from './components/EditarPolitica';
import Usuario from './components/Usuarios';
import Codigos from './components/Codigos';
import Assinaturas from './components/Assinaturas';
import GenerateCode from './components/generate-code';
import Newsletters from './components/Newsletters';
import { version } from '../package.json';
import Loading from './loading';
class App extends Component {
  render() {
    const isProduction = process.env.NODE_ENV === 'production';
    return (
      <CacheBuster
        currentVersion={version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={true} //If true, the library writes verbose logs to console.
        loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      >
            <BrowserRouter>
              <Switch>
                {/* PUBLIC ROUTES */}
                <PublicRoute  component={Login} path="/login" exact restricted={false} />
                <PrivateRoute component={Home} path="/" exact restricted={false} />
                <PrivateRoute component={Plano} path="/planos" exact restricted={false} />
                <PrivateRoute component={Usuario} path="/usuarios" exact restricted={false} />
                <PrivateRoute component={Codigos} path="/codigos" exact restricted={false} />
                <PrivateRoute component={Instrumento} path="/instrumentos" exact restricted={false} />
                <PrivateRoute component={glossario} path="/glossario" exact restricted={false} />
                <PrivateRoute component={politica} path="/politica" exact restricted={false} />
                <PrivateRoute component={TermosCondicoes} path="/termos" exact restricted={false} />
                <PrivateRoute component={sinonimos} path="/sinonimos" exact restricted={false} />
                <PrivateRoute component={ImportInstrumento} path="/importInstrumento" exact restricted={false} />
                <PrivateRoute component={ImportGlossario} path="/importGlossario" exact restricted={false} />
                <PrivateRoute component={ImportSinonimo} path="/importSinonimo" exact restricted={false} />

                <PrivateRoute component={EditarInstrumento} path="/editar-instrumento/:instrumento" exact restricted={false} />
                <PrivateRoute component={EditarTermos} path="/editar-termos/:termo" exact restricted={false} />
                <PrivateRoute component={EditarGlossario} path="/editar-glossario/:glossario" exact restricted={false} />
                <PrivateRoute component={EditarPolitica} path="/editar-politica/:politica" exact restricted={false} />
                <PrivateRoute component={EditarSinonimos} path="/editar-sinonimos/:sinonimo" exact restricted={false} />

                <PrivateRoute component={EditarInstrumento} path="/add-instrumento" exact restricted={false} />
                {/* <PrivateRoute component={InserirTermos} path="/add-termos/:termo" exact restricted={false} />
                <PrivateRoute component={InserirGlossario} path="/add-glossario/:glossario" exact restricted={false} />
                <PrivateRoute component={InserirPolitica} path="/add-politica/:politica" exact restricted={false} />
                <PrivateRoute component={InserirSinonimos} path="/add-sinonimos/:sinonimo" exact restricted={false} /> */}

                {/* <PrivateRoute component={ExcluirInstrumento} path="/excluir-instrumento/:instrumento" exact restricted={false} />
                <PrivateRoute component={ExcluirTermos} path="/excluir-termos/:termo" exact restricted={false} />
                <PrivateRoute component={ExcluirGlossario} path="/excluir-glossario/:glossario" exact restricted={false} />
                <PrivateRoute component={ExcluirPolitica} path="/excluir-politica/:politica" exact restricted={false} />
                <PrivateRoute component={ExcluirSinonimos} path="/excluir-sinonimos/:sinonimo" exact restricted={false} /> */}

                <PrivateRoute component={Assinaturas} path="/assinaturas" exact restricted={false} />
                <PrivateRoute component={GenerateCode} path="/generate-code" exact restricted={false} />
                <PrivateRoute component={Newsletters} path="/newsletters" exact restricted={false} />
                <PublicRoute  component={GenericNotFound} />
              </Switch>
                <ToastContainer
                    position="bottom-center"
                    autoClose={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                />
            </BrowserRouter>


        {/* }} */}
      </CacheBuster>
    );
  }
}

export default App;
